<template>
  <div id="account-orders" role="tabpanel" aria-labelledby="account-orders-tab">
    <loading :show="listLoading" />
    <div class="myaccount-orders">
      <div style="margin-bottom: 20px">
        <div style="float:  right">
          <el-select v-model="params.flag" :placeholder="$i18n.PLACE_SELECT">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <h4 class="small-title">{{ $i18n.Order.Title }}</h4>
      </div>
      <div class="table-responsive">
        <div class="item-div-box">
          <div class="item-box" v-for="(item, idx) in datas" :key="item.orderId">
            <div class="item">
              <div class="name">{{ $i18n.Order.th.ORDER }}</div>
              <div class="val">
                <router-link class="account-order-id" :to="`order/${item.orderId}`">#{{ item.sn }}</router-link>
              </div>
            </div>
            <div class="item">
              <div class="name">{{ $i18n.Order.th.DATE }}</div>
              <div class="val">{{ item.createTime }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $i18n.Order.th.STATUS }}</div>
              <div class="val">{{ item.flagText }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $i18n.Order.th.TOTAL }}</div>
              <div class="val">€{{ item.totalAmount }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $i18n.Order.th.D_TIME }}</div>
              <div class="val">{{ item.expressCorpName || '-' }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $i18n.Order.th.D_NO }}</div>
              <div class="val">{{ item.expressNo || '-' }}</div>
            </div>
            <div class="control" style="margin-top: 10px;">
              <template v-if="item.flag == -1">
                <!-- {{ item.flagText }} -->
                <span style="cursor: pointer;" @click="handleRemoveOrder(idx)">Eliminar</span>
              </template>
              <template v-if="item.flag == 1">
                <router-link :to="`/order/${item.orderId}`" class="torress-btn torress-btn_dark torress-btn_sm">
                  <span>Ver</span>
                </router-link>
                <span class="cancel-order" @click="handleCancelOrder(idx)">
                  Cancelar
                </span>
              </template>
            </div>
          </div>
        </div>

        <table class="item-table-box table table-bordered table-hover">
          <tbody>
            <tr>
              <th width="140">{{ $i18n.Order.th.ORDER }}</th>
              <th>{{ $i18n.Order.th.DATE }}</th>
              <th>{{ $i18n.Order.th.STATUS }}</th>
              <th>{{ $i18n.Order.th.TOTAL }}</th>
              <th>
                {{ $i18n.Order.th.D_TIME }}
                <!-- //运输公司 -->
              </th>
              <th>{{ $i18n.Order.th.D_NO }}</th>
              <th></th>
            </tr>
            <tr v-for="(item, idx) in datas" :key="item.orderId">
              <td>
                <router-link class="account-order-id" :to="`order/${item.orderId}`">#{{ item.sn }}</router-link>
              </td>
              <td>{{ item.createTime }}</td>
              <td>{{ item.flagText }}</td>
              <td>€{{ item.totalAmount }}</td>
              <td>{{ item.expressCorpName || '-' }}</td>
              <td>{{ item.expressNo || '-' }}</td>
              <td style="white-space: nowrap;">
                <template v-if="item.flag == -1">
                  <!-- {{ item.flagText }} -->
                  <span style="margin-left: 15px; cursor: pointer;" @click="handleRemoveOrder(idx)">Eliminar</span>
                </template>
                <template v-if="item.flag == 1">
                  <router-link :to="`/order/${item.orderId}`" class="torress-btn torress-btn_dark torress-btn_sm">
                    <span>Ver</span>
                  </router-link>
                  <span class="cancel-order" @click="handleCancelOrder(idx)">
                    Cancelar
                  </span>
                </template>
              </td>
            </tr>
            <tr v-if="datas.length < 1">
              <td colspan="7">{{ $i18n.NO_ORDER }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <page-component :page-config="pageConfigTotal" @changeCurrentPage="changePage"></page-component>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { getOrderList, putCancelOrder, putDelOrder } from '@/api'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)
import loading from '@/views/public/loading.vue'
import pageComponent from '@/components/page'

export default {
  components: {
    loading,
    pageComponent
  },
  data() {
    return {
      datas: [],
      pageConfigTotal: {},
      params: {
        flag: 0, //-1已取消, 1待付款, 2已付款, 3已发货, 4已完成, 5已评价
        page: 1,
        pageLimit: 10
      }
    }
  },
  filters: {
    parseCorpid(v) {
      if (!v || v == 0) {
        return '-'
      }
      return v
    }
  },
  computed: {
    options() {
      return Array(5)
        .fill()
        .map((_, i) => {
          return {
            value: i,
            label: this.$i18n.Order.Status[i]
          }
        })
    }
  },
  watch: {
    'params.flag'() {
      this.params.page = 1
      this.posts()
    }
  },
  created() {
    this.posts()
  },
  methods: {
    handleRemoveOrder(idx) {
      this.$confirm('¿confirmar la eliminación?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'error'
      })
        .then(res => {
          const { orderId } = this.datas[idx]
          putDelOrder({ order_id: orderId }).then(res => {
            this.datas.splice(idx, 1)
          })
        })
        .catch(err => {})
    },
    handleCancelOrder(idx) {
      const { orderId, flag } = this.datas[idx]

      this.$confirm('¿Confirmar la cancelación del pedido?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'error'
      })
        .then(res => {
          putCancelOrder({ orderId }).then(res => {
            // console.log(res)
            this.$set(this.datas[idx], 'flag', -1)
            this.$set(this.datas[idx], 'flagText', 'Cancelado')
          })
        })
        .catch(err => {})

      // if (flag == 1 && confirm('¿Confirmar la cancelación del pedido?')) {
      //   putCancelOrder({ orderId }).then(res => {
      //     // console.log(res)
      //     this.$set(this.datas[idx], 'flag', -1)
      //     this.$set(this.datas[idx], 'flagText', 'Cancelado')
      //   })
      // }
    },
    changePage(e) {
      if (this.params.page != e) {
        this.posts()
      }
    },
    posts() {
      this.listLoading = true

      getOrderList(this.params).then(res => {
        this.listLoading = false
        if (res.data.status !== 'fail') {
          let data = res.data.data
          // console.log(data.list)
          this.datas = data.list

          this.pageConfigTotal = {
            total: data.pageLimit * data.totalPages,
            pageSize: data.pageLimit,
            pageNo: data.page || 1
          }
        } else {
          this.$toasted.error(res.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less">
#account-orders {
  a.account-order-id {
    word-break: break-all;
    max-width: 140px;
  }
  .item-box {
    border-bottom: 1px solid #efefef;
    &:first-child {
      border-top: 1px solid #efefef;
    }
    padding: 10px 0;
  }

  .item-box .item {
    display: flex;
    /* border-bottom:1px solid #efefef; */
    padding: 3px 0;
  }
  .item-box .item .name {
    padding-right: 10px;
  }
  .item-box .item .name:after {
    content: ':';
    margin-left: 4px;
  }
  .item-box .item .val {
    font-size: 90%;
    color: #666;
    text-align: left;
  }

  position: relative;

  .cancel-order {
    color: #fed100;
    font-size: 14px;
    cursor: pointer;
  }
  .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .torress-btn_sm {
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  .item-div-box {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    .item-div-box {
      display: block;
      .torress-btn_sm {
        display: inline-block;
        margin: 10px;
      }
      .cancel-order {
        margin: 10px;
      }
    }
    .item-table-box {
      display: none;
    }
  }
}
</style>
